import React, { useState } from 'react'
import { Container, Typography, Paper, Button, Grid, Tooltip } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import key from '../../assets/images/key.svg'
import { withRouter } from 'react-router-dom'
import firebase from '../../firebase'
import Header from './../Header/HeaderLogin'
import google from './../../assets/images/google.svg'
import racoon from '../../assets/images/racoon_w.svg'

const baseUrl = 'https://api.sistematurnos.ktbo.mx'

const styles = theme => ({
	paper: {
		margin: '40px 0',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '10px',
		[theme.breakpoints.up('sm')]: {
			padding: '20px 40px',
		  },
		boxShadow: 'none',
		border: '1px solid #e3e3e3',
		borderRadius: '0'
	},
	avatar: {
		width: '70px',
		height: '70px',
		background: 'none'
	},
	submit: {
		margin: '40px 0',
		border: '0',
		borderRadius: '0',
		background: '#4285F4',
		padding: '0 10px 0 0'
	},
	imgButton:{
		marginRight: '10px'
	},
	textLogin:{
		textAlign: 'center',
		[theme.breakpoints.up('sm')]: {
			textAlign: 'left'
		  },
	},
	racoonLink:{
		width: '70px',
		height: '70px',
		background: 'black',
		display: 'flex',
		alignItems: 'center',
		justifyContent:'center',
		borderRadius: '50%',
		padding: '10px',
		marginRight: '20px'
	},
	racoon:{
		width: '100%'
	}
});


function SignIn(props) {
	const { classes } = props;
	const [error, setError] = useState(false)
	
	async function login() {
		setError(false);
		try {
			await firebase.login().then(response => {
				localStorage.setItem('token', response.credential.accessToken);
				const urlAuth = `${baseUrl}/auth2`
				const dataAuth = {
					"email" : response.user.email,
					"token" : response.credential.accessToken,
					"displayname": response.user.displayName,
					"photourl": response.user.photoURL
				}
				fetch(urlAuth, {
					method: 'POST',
					body: JSON.stringify(dataAuth), 
					headers:{
					  'Content-Type': 'application/json'
					}
				  }).then(res => res.json()).then(response => {
					  console.log(response)
					if(response.message === "User not found"){
						localStorage.removeItem('token');
						localStorage.removeItem('user');
						setError(true);
					}else{
						localStorage.setItem('user', JSON.stringify(response));
						props.history.push('/dashboard')
					}
				  })
				  .catch(error => console.error('Error:', error))
			})
		} catch(error) {
			console.log(error.message)
		}
	}

	return (<>
		<Header/>
		<Container maxWidth='sm'>
			<Paper className={classes.paper}>
				<img src={key} alt="login" className={classes.avatar}/>
				<form className={classes.form} onSubmit={e => e.preventDefault() && false}>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						onClick={login}
						className={classes.submit}>
						<img src={google} alt="Login Google" className={classes.imgButton}/>Sign in with Google
          			</Button>
				</form>
				<Typography component="p" variant="caption" className={classes.textLogin} paragraph>
				Favor de iniciar sesión con tu cuenta de KTBO o Blok Media para hacer el registro de los días que vas a querer ir la oficina.
       			</Typography>
				{error &&
					<Typography component="p" variant="caption" color="secondary" className={classes.textLogin}>
						Sólo puedes iniciar sesión con cuentas de KTBO o Block Media
					</Typography>
				}
			</Paper>
		</Container>
		<Grid container spacing={1}>
			<Grid item xs={12} align="right">
				<Tooltip title="Ayuda" placement="top">
					<a href="mailto:mapa@ktbo.mx?Subject=Problema KTBO Turnos" className={classes.racoonLink} target="_blank" rel="noopener noreferrer"><img src={racoon} alt="¡¡Ayúdame Mapachus!!" className={classes.racoon}/></a>
				</Tooltip>
			</Grid>
		</Grid>
		</>
	)
}

export default withRouter(withStyles(styles)(SignIn))