import React, { useEffect, useState } from 'react'
import { Container, Typography, Button, Tooltip } from '@material-ui/core'
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles'
import firebase from '../../firebase'
import { withRouter, useHistory } from 'react-router-dom'
import Header from './../Header/Header'
import Calendar from './Subcomponents/Calendar';
import ReservedDays from './Subcomponents/ReservedDays';
import racoon from '../../assets/images/racoon_w.svg'

const styles = theme => ({
	gridContainerCenter:{
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	userName: {
		textTransform: 'uppercase',
		fontSize: '1.5rem'
	},
	submit: {
		[theme.breakpoints.up('md')]: {
			marginTop: '20px'
        },
		background: 'transparent',
		color: 'black',
		textTransform: 'uppercase'
	},
	marginContainer:{
		marginTop: '40px',
		marginBottom: '20px'
	},
	rowMargin:{
		margin: '40px 0'
	},
	calendarCol:{
		width: '20%',
		borderRight: '1px solid #e3e3e3',
	},
	barCalendar:{
		width: '100%',
		display: 'block',
		height: '7px',
		border: '1px solid black',
		borderRadius: '5px',
		marginBottom: '2px'
	},
	borderContainer: {
		border: '1px solid #e3e3e3',
		borderRadius: '5px',
		padding: '10px'
	},
	marginBlockCalendar:{
		marginBottom: '10px',
		display:'flex',
		alignItems:'center',
		justifyContent:'center'
	},
	confirmButton:{
		padding: '15px 40px',
		border: '2px solid black',
		display: 'block',
		margin: 'auto',
		letterSpacing: '6px',
		borderRadius: 0
	},
	racoonLink:{
		width: '70px',
		height: '70px',
		background: 'black',
		display: 'flex',
		alignItems: 'center',
		justifyContent:'center',
		borderRadius: '50%',
		padding: '10px',

	},
	racoon:{
		width: '100%'
	}
})

const Dashboard = (props) => {
	const { classes } = props;
	const History = useHistory();
	const [userData, setUserData] = useState(null);
	const [selectedDay, setSelectedDay] = useState(null);
	
	useEffect(() => {
		if(localStorage.getItem('user')) {
			setUserData(JSON.parse(localStorage.getItem('user')))
		}else{
			History.push('/login')
		}
	},[History])

	useEffect(()=>{
		if(userData){
			setSelectedDay(userData.calendar[0])
		}
	},[userData])

	return (
		<>
		<Header area={userData && userData.area } />
		<Container maxWidth='md' className={classes.marginContainer}>
			<Grid container className={classes.gridContainerCenter} spacing={1}>
				<Grid item xs={12}>
					<Typography component="h4" variant="h4" className={classes.userName} paragraph>
						¡Hola, {userData && userData.displayname}!
					</Typography>
				</Grid>
			</Grid>
			<Typography component="p" variant="body2" paragraph>
			Registra tu regreso a Wacibedo. Tendrás visibilidad de los siguiente 5 días hábiles y podrás seleccionar hasta 3 días por semana, siempre y cuando haya disponibilidad.
			</Typography>
			<Grid container className={classes.rowMargin} spacing={1}>
				<Grid item xs={12} md={8}>
				{userData && 
					<Calendar data={userData.calendar} selectedDay={selectedDay} setSelectedDay={setSelectedDay} setUserData={setUserData} assistance={userData.assistance} />
				}
				</Grid>
				<Grid item xs={12} md={4}>
				{userData &&
					<ReservedDays data={userData.assistance} setUserData={setUserData} setSelectedDay={setSelectedDay}/>
				}
				</Grid>
			</Grid>
			<Grid container spacing={1}>
				<Grid item xs={12} align="center" className={classes.hideOnMobile}>
					<Button
						type="submit"
						onClick={logout}
						className={classes.submit}>
							Cerrar sesión
					</Button>
				</Grid>
			</Grid>
			<Grid container spacing={1}>
				<Grid item xs={12} align="right">
					<Tooltip title="Ayuda" placement="top">
						<a href="mailto:mapa@ktbo.mx?Subject=Problema KTBO Turnos" className={classes.racoonLink} target="_blank" rel="noopener noreferrer"><img src={racoon} alt="¡¡Ayúdame Mapachus!!" className={classes.racoon}/></a>
					</Tooltip>
				</Grid>
			</Grid>
		</Container>
		</>
	)

	async function logout() {
		await firebase.logout();
		History.push('/login')
	}
}

export default withRouter(withStyles(styles)(Dashboard))