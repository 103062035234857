import React from 'react'
import './styles.scss'
import Login from '../Login/Login'
import Dashboard from '../Dashboard/Dashboard'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

const theme = createMuiTheme({
	typography: {
		h1: {
			fontFamily: ['GothamBold'].join(',')
		},
		h3:{
			fontFamily: ['GothamBold'].join(',')
		},
		h4:{
			fontFamily: ['GothamBold'].join(',')
		},
		h5:{
			fontFamily: ['GothamBold'].join(',')
		},
		body2:{
			fontFamily: ['GothamBook'].join(',')
		},
		subtitle1:{
			fontFamily: ['GothamBold'].join(',')
		}
	  },
})

export default function App(props) {

	const ProtectedRoute = ({component: Component, ...rest}) => {
		return (
			<Route 
			{...rest} 
			render= {
				(props => {
					if (localStorage.getItem('user')) {
						return <Component {...props} />
					} else {
						return <Redirect to = {
							{
								pathname: "/login",
								state: {
									from: props.location
								}
							}
						}/>
					}
				})
			}/>
		);
	}
	
	return (
		<MuiThemeProvider theme={theme}>
			<CssBaseline />
			<Router>
				<Switch>
					<ProtectedRoute exact path="/" component={Dashboard} />
					<Route exact path="/login" component={Login} />
					<ProtectedRoute exact path="/dashboard" component={Dashboard} />
				</Switch>
			</Router>
		</MuiThemeProvider>
	)
}