import React, { useEffect } from 'react'
import logo from '../../assets/images/logo.svg'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = theme => ({
  nav: {
    height: '80px',
    background: 'black',
    padding: '10px',
    [theme.breakpoints.up('sm')]: {
      padding: '10px 50px'
    },
  },
  navWrapper:{
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  logo:{
    lineHeight: 1,
  },
  imgLogo: {
      width: '100%',
      maxWidth: '40px',
      marginRight: '20px'
  },
  textArea:{
    color: 'white',
    listStyle: 'none',
    textTransform: 'uppercase'
  },
  contentLogo:{
    display: 'flex',
    alignItems: 'center'
  },
  h1:{
    color: 'white',
    display: 'none',
    fontFamily: 'GothamBold',
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
  }
})

const Header = (props) => {
  useEffect(() =>{},[props])
  const { classes } = props;
  return (
      <header>
        <nav id="nav-mobile" className={classes.nav}>
          <div className={classes.navWrapper}>
            <div className={classes.contentLogo}><a href="/" className={classes.logo}><img src={logo} alt="KTBO" className={classes.imgLogo}/></a><h1 className={classes.h1}>Sistema Regreso Wacibedo</h1></div>
            <ul id="nav-mobile" className="">
            {props.area && (<>
              <li className={classes.textArea}>{props.area}</li>
            </>) 
          }
          </ul> 
          </div>
        </nav>
      </header>
  )
}

export default withStyles(styles)(Header)
