import React, {useState} from 'react'
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles'
import { Typography, Modal, Button } from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/Remove';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Moment from 'react-moment';

const baseUrl = 'https://api.sistematurnos.ktbo.mx'

const styles = theme => ({
    containerPadding:{
        [theme.breakpoints.up('md')]: {
            paddingLeft: '10%'
          },
    },
    reserveTitle:{
        borderBottom: '1px solid black',
        textTransform: 'uppercase',
        lineHeight: 3
    },
	removeIcon: {
        color: 'white',
        background: '#e3e3e3',
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        marginRight: '10px'
    },
    textList:{
        textTransform: 'uppercase',
    },
    listItem:{
        borderBottom: '1px solid #e3e3e3',
        '&:last-of-type':{
            borderBottom: 'none'
        }
    },
    paper: {
        position: 'absolute',
        width: '80%',
        maxWidth: '500px',
        [theme.breakpoints.up('md')]: {
            width: '60%',
        },
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 4, 4),
    },
    confirmButton:{
        padding: '15px 20px',
        [theme.breakpoints.up('md')]: {
            padding: '15px 40px',
        },
		border: '2px solid black',
		display: 'block',
		margin: '40px auto 0',
		letterSpacing: '6px',
		borderRadius: 0
    },
    h2:{
        textAlign: 'center',
        fontFamily: 'GothamBold',
        textTransform: 'uppercase',
        marginBottom: '20px'
    },
    hr:{
        border: 0,
        background: 'black',
        height: '1px',
        marginBottom: '20px'
    },
    here:{
        fontFamily: 'GothamBold',
        textTransform: 'uppercase',
        textDecoration: 'underline'
    }
})

const ReservedDays = (props) => {
    const { classes } = props;
    const [open, setOpen] = useState(false);
    const [deleteItem, setDeleteItem] = useState(null);
    const [errorCancel, setErrorCancel] = useState('');
      
    const getModalStyle = () =>  {
        return {
          top: `50%`,
          left: `50%`,
          transform: `translate(-50%, -50%)`,
        };
    }

    const [modalStyle] = useState(getModalStyle);
    
    const handleOpen = (item) => {
        setErrorCancel('')
        setDeleteItem(item)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const bodyModal = (
        <div style={modalStyle} className={classes.paper}>
            <h2 id="simple-modal-title" className={classes.h2}>Cancelación</h2>
            <hr className={classes.hr}/>
            <p id="simple-modal-description">
            Por favor confirma tu cancelación de días y así habilitar disponibilidad para otras personas de tu equipo.
            </p>
            <Button className={classes.confirmButton} onClick={()=> removeDay()}>Confirmar</Button>
            {errorCancel !== '' &&
            <Typography component="p" variant="caption" color="secondary" className={classes.reserveTitle} align='center'>
                {errorCancel}
            </Typography>
            }
        </div>)

    const removeDay = () => {
        fetch(`${baseUrl}/api/assistance/cancel`, {
            method: 'POST',
            headers:{
                'Authorization': localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            body:JSON.stringify({"assistance_at" : deleteItem.assistance_at.split("T")[0]})
            }).then(res => res.json())
            .catch(error => console.error('Error:', error))
            .then(response => {
                if(response.message === "assistance cancel ok"){
                    const urlUser = `${baseUrl}/api/user/info`
                    fetch(urlUser, {
                        method: 'GET',
                        headers:{
                            'Authorization': localStorage.getItem('token')
                        }
                    }).then(res => res.json())
                    .catch(error => console.error('Error:', error))
                    .then(user => {
                        if(user && user.calendar){
                            props.setUserData(user);
                            props.setSelectedDay(user.calendar[0])
                            handleClose();
                        }
                    })
            }
            if(response.code === 105){
                setErrorCancel('Las cancelaciones sólo aplican antes de 24hrs hábiles')
            }
        })
    }
    
    return (<>
        <Grid container spacing={1} className={classes.containerPadding}>
            <Grid item xs={12}>
                <Typography component="h5" variant="subtitle1" color="initial" className={classes.reserveTitle}>
                    Tus días reservados son:
                </Typography>
                <List>
                    {props.data && props.data.length !== 0 ? 
                   props.data.map((item, index) =>
                   <ListItem button className={`${classes.listItem} ${classes.textList}`} key={index} onClick={(e)=> handleOpen(item)}>
                        <ListItemIcon>
                            <RemoveIcon className={classes.removeIcon}/><Moment format="dddd DD MMMM">{item.assistance_at}</Moment>
                        </ListItemIcon>
                        <ListItemText primary={item.day} className={classes.textList}/>
                    </ListItem>
                    )
                : <Typography component="h5" variant="subtitle1" color="initial">
                    Aún no has reservado días
                </Typography>
                }
                </List>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" color="initial" paragraph>
                Recuerda que tenemos protocolos estrictos de acceso, así como revisiones que debes realizar antes de salir de casa. Además de varias medias a tomar en cuenta dentro de los pisos 8 y 9.
                Consulta <a href="https://sites.google.com/kontrabando.com.mx/regresando/p%C3%A1gina-principal/reglas" className={classes.here} target="_blank"  rel="noopener noreferrer">aquí</a> toda la información.
                </Typography>
            </Grid>
        </Grid>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <>
            {bodyModal}
            </>
      </Modal>
      </>
    )
}

export default withStyles(styles)(ReservedDays);