import React, { useRef, useState } from 'react'
import Grid from '@material-ui/core/Grid';
import { Typography, Button, Modal, Checkbox } from '@material-ui/core'
import {ChevronLeft, ChevronRight, AddCircle} from '@material-ui/icons'
import withStyles from '@material-ui/core/styles/withStyles'
import Slider from "react-slick";
import '../../../assets/css/slick.min.css'
import Moment from 'react-moment';
import 'moment/locale/es';

const baseUrl = 'https://api.sistematurnos.ktbo.mx'

const styles = theme => ({
    dateRangeAlign:{
        verticalAlign: 'middle'
    },
	gridContainerCenter:{
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	submit: {
		marginTop: '20px',
		background: 'transparent',
		color: 'black',
		textTransform: 'uppercase'
    },
    month:{
        textTransform: 'uppercase'
    },
	marginContainer:{
		marginTop: '40px',
		marginBottom: '20px'
	},
	rowMargin:{
		margin: '40px 0'
	},
	calendarCol:{
        padding: '5px',
        borderRadius: '5px'
	},
	barCalendar:{
		width: '100%',
		display: 'block',
		height: '7px',
		border: '1px solid #e3e3e3',
		borderRadius: '5px',
		marginBottom: '2px'
    },
    barcalendarEmptySelected: {
        borderColor: 'black',
        background: 'transparent'
    },
    barCalendarFullSelected:{
        background: 'black',
        borderColor: 'black'
    },
    barCalendarFullNoSelected:{
        background: '#e3e3e3',
        borderColor: '#e3e3e3'
    },
    barCalendarEmptyNoSelected:{
        background: 'transparent',
        borderColor: '#e3e3e3'
    },
	borderContainer: {
		border: '1px solid #e3e3e3',
		borderRadius: '5px',
		padding: '5px 0'
	},
	marginBlockCalendar:{
		marginBottom: '10px',
		display:'flex',
		alignItems:'center',
		justifyContent:'center'
    },
    marginBlockAddDate: {
        marginTop: '40px'
    },
    selected: {
        background: '#e3e3e3',
        color: 'black'
    },
    noSelected: {
        color: '#e3e3e3'
    },
    divide:{
        border: 0,
        background: '#e3e3e3',
        height: '1px',
        marginBottom: '20px'
    },
    alignIcon:{
        verticalAlign: 'middle',
        marginRight: '10px'
    },
    disabled: {
        color: '#e3e3e3',
        pointerEvents: 'none'
    },
    paper: {
        position: 'absolute',
        width: '80%',
        maxWidth: '500px',
        [theme.breakpoints.up('md')]: {
            width: '60%',
        },
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 4, 4),
    },
    confirmButton:{
        padding: '15px 20px',
        [theme.breakpoints.up('md')]: {
            padding: '15px 40px',
        },
		border: '2px solid black',
		display: 'block',
		margin: '40px auto 0',
		letterSpacing: '6px',
		borderRadius: 0
    },
    h2:{
        textAlign: 'center',
        fontFamily: 'GothamBold',
        textTransform: 'uppercase',
        marginBottom: '20px'
    },
    hr:{
        border: 0,
        background: 'black',
        height: '1px',
        marginBottom: '20px'
    },
    linkLegals:{
        textDecoration: 'underline',
        fontFamily: 'GothamBold'
    },
    disabledConfirm: {
        borderColor: '#e3e3e3',
        color: '#e3e3e3',
        pointerEvents: 'none'
    },
})

const Calendar = (props) => {
    const { classes } = props;
    const sliderRef = useRef(null);
    const [reserved, setReserved] = useState();
    const [legals, setLegals] = useState(false);

    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive:[{
            breakpoint: 850,
                settings:{
                    slidesToShow: 4
                },
            },{
            breakpoint: 672,
                settings:{
                    slidesToShow: 3
                }
            },{
            breakpoint: 500,
                settings:{
                    slidesToShow: 2
                } 
            }
        ]
      };

    const next = () =>{
        sliderRef.current.slickNext();
    }
    const prev = () =>{
        sliderRef.current.slickPrev();
    }

    const [open, setOpen] = useState(false);

    const newDay = () => {
        fetch(`${baseUrl}/api/assistance/new`, {
            method: 'POST',
            headers:{
              'Authorization': localStorage.getItem('token'),
              'Content-Type': 'application/json'
            },
            body:JSON.stringify({"assistance_at" : props.selectedDay.day.split(" ")[0]})
            }).then(res => res.json())
            .catch(error => console.error('Error:', error))
            .then(response => {
                if(response.message === "assistance ok"){
                    const urlUser = `${baseUrl}/api/user/info`
                    fetch(urlUser, {
                        method: 'GET',
                        headers:{
                            'Authorization': localStorage.getItem('token')
                        }
                    }).then(res => res.json())
                    .catch(error => console.error('Error:', error))
                    .then(user => {
                        setReserved(true);
                        if(user && user.calendar && props.setUserData){
                            props.setUserData(user);
                            props.setSelectedDay(user.calendar[0])
                        }
                    })
                }
            })
    }

    const getModalStyle = () =>  {
        return {
          top: `50%`,
          left: `50%`,
          transform: `translate(-50%, -50%)`,
        };
    }

    const [modalStyle] = useState(getModalStyle);
    
    const handleOpen = (e) => {
        e.preventDefault()
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setReserved(false)
    };
    
    const changeLegals = () => {
        setLegals(!legals)
    }

    const bodyModal = (
        <div style={modalStyle} className={classes.paper}>
            <h2 id="simple-modal-title" className={classes.h2}>Confirmar reservación</h2>
            <hr className={classes.hr}/>
            {!reserved ?(<>
                    <p>
                    ¿Estás seguro de que deseas reservar este día?
                    </p>
                    <div>
                        <Checkbox
                            checked={legals}
                            onChange={changeLegals}
                            inputProps={{ 'aria-label': 'He leído los términos y condiciones' }}
                            name="legals"
                            color="initial"
                        /> He leído y estoy de acuerdo con los <a href='https://sites.google.com/kontrabando.com.mx/regresando/p%C3%A1gina-principal/reglas' className={classes.linkLegals} target="_blank" rel="noopener noreferrer">términos y condiciones</a>
                    </div>
                    <Button className={[classes.confirmButton, !legals && classes.disabledConfirm]} onClick={()=> newDay()}>Confirmar</Button>
                </>)
                :
                (<>
                <Typography component="h5" variant="subtitle1" align="center" paragraph>
                   Reserva realizada con éxito
                </Typography>
                <Button className={classes.confirmButton} onClick={()=> handleClose()}>Cerrar</Button>
                </>)
            }
        </div>)
    
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                <Typography component="h5" variant="h5" paragraph className={classes.month}>
                    {props.selectedDay &&
                        <Moment format="MMMM" locale="es">{props.selectedDay.day.split(" ")[0]}</Moment>
                    }
                </Typography>
                </Grid>
                <Grid item xs={6}>
                <Typography component="p" align="right" paragraph className="arrows">
                <ChevronLeft className={classes.dateRangeAlign} onClick={() => prev()}/><ChevronRight className={classes.dateRangeAlign} onClick={() => next()}/>
                </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={1} className={classes.borderContainer}>
                        <Slider {...settings} ref={sliderRef}>
                            {props.data && props.selectedDay && props.data.map((item, index) => {
                                return (
                                <div className={props.selectedDay.day === item.day ? `${classes.selected} ${classes.calendarCol}`: `${classes.calendarCol}`} key={index} onClick={() => props.setSelectedDay(item)}>
                                    <Grid container spacing={1} className={classes.marginBlockCalendar}>
                                    <Grid item xs={6}>
                                        <Typography variant="h4" component="h2" className={props.selectedDay.day === item.day ? `${classes.selected}`:`${classes.noSelected}`}>
                                            <Moment format="DD">{item.day.split(" ")[0]}</Moment>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {props.selectedDay && <>
                                            <span className={(item.users_registered / item.positions * 100) === 100 ? (props.selectedDay.day === item.day ? `${classes.barCalendar} ${classes.barCalendarFullSelected}` : `${classes.barCalendar} ${classes.barCalendarFullNoSelected}`) : (props.selectedDay.day === item.day ? `${classes.barCalendar} ${classes.barcalendarEmptySelected}` : `${classes.barCalendar} ${classes.barCalendarEmptyNoSelected}`) }></span>
                                            <span className={(item.users_registered / item.positions * 100) >= 75 ? (props.selectedDay.day === item.day ? `${classes.barCalendar} ${classes.barCalendarFullSelected}` : `${classes.barCalendar} ${classes.barCalendarFullNoSelected}`) : (props.selectedDay.day === item.day ? `${classes.barCalendar} ${classes.barcalendarEmptySelected}` : `${classes.barCalendar} ${classes.barCalendarEmptyNoSelected}`)}></span>
                                            <span className={(item.users_registered / item.positions * 100) >= 50 ? (props.selectedDay.day === item.day ? `${classes.barCalendar} ${classes.barCalendarFullSelected}` : `${classes.barCalendar} ${classes.barCalendarFullNoSelected}`) : (props.selectedDay.day === item.day ? `${classes.barCalendar} ${classes.barcalendarEmptySelected}` : `${classes.barCalendar} ${classes.barCalendarEmptyNoSelected}`)}></span>
                                            <span className={(item.users_registered / item.positions * 100) >= 25 ? (props.selectedDay.day === item.day ? `${classes.barCalendar} ${classes.barCalendarFullSelected}` : `${classes.barCalendar} ${classes.barCalendarFullNoSelected}`) : (props.selectedDay.day === item.day ? `${classes.barCalendar} ${classes.barcalendarEmptySelected}` : `${classes.barCalendar} ${classes.barCalendarEmptyNoSelected}`)}></span>
                                            </>
                                        }
                                    </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Typography component="p" variant="caption" color={item.status === 'Available' ? 'initial' : 'secondary'} align="center" paragraph>
                                            {item.status === 'Available' ? 'DISPONIBLE':'NO DISPONIBLE'}
                                        </Typography>
                                    </Grid>
                                </div>
                            )})}
                        </Slider>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.marginBlockAddDate}>
                {props.selectedDay &&
                    <Grid item xs={12}>
                        {props.assistance.length < 3 ?
                        <Typography component="h3" variant="subtitle1" color="initial" align="center" paragraph className={props.selectedDay.status === 'Unavailable' ? `${classes.disabled}`: ''}>
                            <a href={'/'} onClick={(e) => handleOpen(e)}> <AddCircle className={classes.alignIcon}/>RESERVAR DÍA</a>
                        </Typography> :
                        <Typography component="p" variant="caption" color="secondary" align="center" paragraph>
                             YA HAS RESERVADO EL MÁXIMO DE DÍAS PERMITIDO POR SEMANA
                            </Typography>
                        }
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                        >
                            <>
                                {bodyModal}
                            </>
                        </Modal>
                    </Grid>
                }
                <Grid item xs={12}>
                    <hr className={classes.divide}/>
                    {props.selectedDay &&
                        <Typography component="p" variant="body1" color="initial" align="center" paragraph>
                            LUGARES DISPONIBLES: <b>{props.selectedDay.positions - props.selectedDay.users_registered}</b>
                        </Typography>
                    }
                </Grid>
            </Grid>
        </>
    )
} 

export default withStyles(styles)(Calendar);
