import app from 'firebase/app'
import 'firebase/auth'
const baseUrl = 'https://api.sistematurnos.ktbo.mx'

const config = {
	apiKey: "AIzaSyB77WsofvqwLc6efJxTEA0yFSnUoHhUoWo",
	authDomain: "ktbo-queue.firebaseapp.com",
	databaseURL: "https://ktbo-queue.firebaseio.com",
	projectId: "ktbo-queue",
	storageBucket: "ktbo-queue.appspot.com",
	messagingSenderId: "397816430544",
	appId: "1:397816430544:web:349ec932a3a8b853db03d5",
	measurementId: "G-NVLPFFQRK2"
}

class Firebase {
	constructor() {
		app.initializeApp(config)
		this.auth = app.auth()
	}

	login() {
		return app
        .auth()
        .signInWithPopup(new app.auth.GoogleAuthProvider())
        .catch(this.handleAuthError);
	}

	handleAuthError(error){
        console.log(error)
    }

	logout() {
		const urlLogout = `${baseUrl}/api/user/logout`;
		const tkn = localStorage.getItem('token')
			fetch(urlLogout, {
				method: 'GET',
				headers:{
				  'Authorization': tkn
				}
			  }).then(res => res.json())
			  .catch(error => console.error('Error:', error))
			  .then(out => {
				localStorage.removeItem('user');
				localStorage.removeItem('token');
			})
		return this.auth.signOut()
	}

	isInitialized() {
		return new Promise(resolve => {
			this.auth.onAuthStateChanged(resolve)
		})
		
	} 

	getCurrentUsername() {
		return this.auth.currentUser
	}

}

export default new Firebase();