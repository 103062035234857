import React, { useEffect } from 'react'
import logo from '../../assets/images/logo.svg'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = theme => ({
  nav: {
    height: '40vh',
    background: 'black',
    padding: '10px',
    [theme.breakpoints.up('sm')]: {
      padding: '20px 50px'
    },
  },
  navWrapper:{
    height: '100%',
    display: 'flex',
    alignItems: 'space-between',
    flexFlow: 'column',
    justifyContent: 'space-between'
  },
  logo:{
    lineHeight: 1,
  },
  imgLogo: {
      width: '100%',
      maxWidth: '40px',
      marginRight: '20px'
  },
  textArea:{
    color: 'white',
    listStyle: 'none',
    textTransform: 'uppercase'
  },
  contentLogo:{
    display: 'flex',
    alignItems: 'center'
  },
  h1:{
    color: 'white',
    fontFamily: 'GothamBold',
    fontSize: '2rem',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
        fontSize: '3em',
      },
  }
})

const HeaderLogin = (props) => {
  useEffect(() =>{},[props])
  const { classes } = props;
  return (
      <header>
        <nav id="nav-mobile" className={classes.nav}>
          <div className={classes.navWrapper}>
            <div className={classes.contentLogo}><a href="/" className={classes.logo}><img src={logo} alt="KTBO" className={classes.imgLogo}/></a></div>
            <h1 className={classes.h1}>SISTEMA DE TURNOS</h1>
          </div>
        </nav>
      </header>
  )
}

export default withStyles(styles)(HeaderLogin)
